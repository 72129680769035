var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
import { mapState } from "vuex";
import globalCartFragment from "../../../api/globalCartFragment.graphql";
import addToOrCreateCart from "../../../api/addToOrCreateCart";
import mixins from "vue-typed-mixins";
import NotificationsMixin from "../../ui/NotificationsMixin";
import removeFromCart from "../../../api/mutations/removeFromCart";
import AsyncStatus from "../../../utils/async";
export default mixins(NotificationsMixin).extend({
    data: function () {
        return {
            enteredDiscountCode: "",
            discountCodeStatus: new AsyncStatus(),
        };
    },
    apollo: {
        cart: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query CartQuery($id: ID!) {\n          cart(id: $id) {\n            id\n            ...globalCart\n          }\n        }\n        ", "\n      "], ["\n        query CartQuery($id: ID!) {\n          cart(id: $id) {\n            id\n            ...globalCart\n          }\n        }\n        ", "\n      "])), globalCartFragment),
            variables: function () {
                return {
                    id: this.cartId,
                };
            },
            skip: function () {
                return !this.cartId;
            },
        },
    },
    computed: mapState(["cartId"]),
    methods: {
        addToCart: function (product) {
            addToOrCreateCart(this.$apollo, this.$store, this.$t.bind(this), product);
        },
        removeCartItem: function (item) {
            this.$apollo.mutate(removeFromCart(item));
        },
    },
});
var templateObject_1;
